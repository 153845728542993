.companyBanner {
    padding: 40px 0;
    .heading-block:after {
        content: '';
        display: block;
        margin-top: 10px;
        width: 60px;
        height: 3px;
        background: -webkit-gradient(linear, left top, right top, color-stop(50%, #50e590), color-stop(120%, #0796c6));
        background: linear-gradient(to right, #50e590 50%, #0796c6 120%);
    }
    .header-text-blue {
        line-height: 48px;
        color: #000;
        margin-bottom: 15px;
        font-size: calc(20px + (34 - 20) * ((100vw - 320px) / (1920 - 320)));
        font-weight: 700;
        font-family: Raleway, sans-serif;
    }
    h1>span:not(.nocolor):not(.badge),
    h2>span:not(.nocolor):not(.badge),
    h3>span:not(.nocolor):not(.badge),
    h4>span:not(.nocolor):not(.badge),
    h5>span:not(.nocolor):not(.badge),
    h6>span:not(.nocolor):not(.badge) {
        color: #2878c8;
    }
    .heading-block {
        margin-bottom: 30px;
    }
    .textbox p {
        margin: 3rem 0 0;
    }
    .textbox ul {
        margin: 1rem 0 0;
        display: block;
    }
    .textbox ul li {
        padding: 0 0 0 40px;
        margin: 0 0 1rem;
        position: relative;
        border: none;
        text-align: left;
        font-size: 20px;
        color: #4d5156;
        list-style: none;
    }
    .textbox ul li:before {
        content: '';
        position: absolute;
        border-color: #b0cb1f;
        border-style: solid;
        border-width: 0 4px 4px 0;
        top: 10px;
        left: 16px;
        transform: rotate(45deg);
        height: 15px;
        width: 7px;
    }
    .my_common_button-green {
        background: #b0cb1f;
        color: #fff;
        border: 0;
        margin-top: 20px;
        font-size: 18px;
        padding: 13px 20px;
        font-weight: 600;
        margin-right: 15px;
        text-align: center;
        text-decoration: none;
        text-shadow: none;
        /* text-transform: uppercase; */
        transition: all ease-in-out .5s;
        cursor: pointer;
    }
    .my_common_button-green:hover {
        background: #9fb812;
        opacity: 1;
        color: #ffffff;
    }
    .homepage-hero-customize {
        font-size: 18px;
        font-weight: 600;
        cursor: pointer;
        color: #2878c9;
    }
}

.commonSection {
    padding: 90px 0;
    .commonSection_header {
        margin-bottom: 50px;
    }
    .commonSection_title {
        font-family: Raleway;
        font-weight: 600;
        color: #333;
        margin-bottom: 15px;
        font-size: calc(20px + (32 - 20) * ((100vw - 320px) / (1920 - 320)));
    }
    h1>span:not(.nocolor):not(.badge),
    h2>span:not(.nocolor):not(.badge),
    h3>span:not(.nocolor):not(.badge),
    h4>span:not(.nocolor):not(.badge),
    h5>span:not(.nocolor):not(.badge),
    h6>span:not(.nocolor):not(.badge) {
        color: #2878c8;
    }
    .blue-border-line {
        height: 4px;
        width: 100px;
        background: linear-gradient(to right, #50e590 50%, #0796c6 120%);
        display: block;
        margin: 0 0 auto;
        margin-bottom: 10px
    }
}

.esteemed-clients {
    background-color: #f2f2f2;
}

img {
    max-width: 100%;
}

.port_img_box {
    position: relative;
    background: #fff;
    box-shadow: 3px 3px 6px rgb(0 0 0 / 10%);
    margin-bottom: 25px;
    border-radius: 5px;
}

.front-service-agency {
    text-align: center;
    margin: 20px 0 20px 0;
    padding: 10px 10px 10px 10px;
}

.front-service-agency h3 {
    font-size: calc(17px + (20 - 17) * ((100vw - 320px) / (1920 - 320)));
    margin-bottom: 15px;
    line-height: 28px;
    color: #2878c8;
    font-weight: 600;
}

.service-circle {
    width: 120px;
    height: 120px;
    background: #fff;
    border-radius: 50%;
    position: relative;
    margin: 20px auto;
    transition: .3s;
    border: solid 2px #f0f0f0;
}

.value-images {
    padding: 28px;
}

.case-studies-card {
    cursor: pointer;
    box-shadow: 0 1px 5px 0 rgb(0 0 0 / 10%);
    height: 100%;
    border-radius: 10px;
}

.outer_link {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.case-studies-card .outer_link {
    opacity: 0;
}

.card-image-wrapper {
    position: relative;
    min-height: 78px
}

.card-image-wrapper img {
    border-radius: 10px 10px 0 0;
}

.sub-card-image {
    position: absolute;
    border: 15px solid #fff;
    background: #fff;
    border-radius: 20%;
    top: 67%;
    transform: translate(20%, 0);
    box-shadow: 0 4px 5px 1px rgba(0, 0, 1, .16)
}

.sub-card-image img {
    background: #fff
}

.case-studies-card-text h3 {
    font-size: calc(17px + (20 - 17) * ((100vw - 320px) / (1920 - 320)));
    margin-bottom: 15px;
    line-height: 28px;
    color: #2878c8;
    font-weight: 600;
}

.case-studies-card-text p {
    margin-bottom: 20px;
    font-size: 14px
}

.case-studies-footer {
    padding: 10px 15px 30px
}

.case-studies-card-text {
    margin: 80px 30px 0
}

.case-studies-card {
    cursor: pointer;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, .1);
    height: 100%
}

.case-studies-card:hover {
    transition: all .3s ease-in;
    box-shadow: 0 15px 50px 0 rgba(26, 38, 74, .1)
}

.case-studies-card:hover .case-studies-footer .card-link {
    margin-right: 10px
}

.case-studies-footer .card-link {
    cursor: pointer;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    float: right;
    color: #0054a5;
    font-size: 13px;
    font-weight: 700;
    transition: all .3s ease-in
}

.card-link {
    text-decoration: none
}

.case-studies-footer .card-link:hover {
    margin-right: 10px
}

// careers page css
.fresher__heading {
    margin: 10px 0 20px;
    font-size: 30px;
    font-weight: 700;
}

.btn_wrapper {
    width: 320px;
}

.btn_wrapper .btn-green {
    z-index: 0;
    color: #fff;
    border: 2px solid #b0c21f;
    background-color: #b0c21f;
}

.btn_wrapper .btn-green:hover {
    /* color: #2879c9; */
    border: 2px solid #2879c9;
    box-shadow: inset 400px 0.1px 0.1px 0.1px #2879c9;
}

.esteemed-careers {
    padding: 60px 0;
    background-color: #fff
}

.single-service {
    align-items: center;
    padding: 30px;
    transition: .5s;
    overflow: hidden;
    display: block;
    min-height: 390px;
    background: #fff;
    box-shadow: 0 4px 14px rgba(70, 97, 138, .2);
    margin: 15px 0;
    border: 1px solid transparent;
    width: 100%
}

.single-service h3 {
    font-size: 20px;
    color: #2979c9;
    margin-bottom: 10px;
    font-weight: 500;
    line-height: 28px
}

.single-service img {
    margin-bottom: 15px;
    height: 120px
}

.our-missionCard {
    background-color: #fff;
    padding: 65px 24px 40px;
    position: relative;
    box-shadow: 0 0 5px rgb(0 0 0 / 10%);
    margin: 40px 0;
    position: relative;
    background-color: #fff;
    -webkit-box-shadow: 0 10px 30px 0 rgb(0 0 0 / 5%);
    box-shadow: 0 10px 30px 0 rgb(0 0 0 / 5%);
    width: 100%;
    height: 280px;
    min-height: 310px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.our-missionCard .approachIcon {
    border: 1px solid #ececec;
    position: absolute;
    left: 50%;
    top: -58px;
    margin-left: -50px;
    border: 1px solid #e8e8e8;
    background-color: #fff;
    border-radius: 50%;
    text-align: center;
    height: 110px;
    width: 110px;
    line-height: 100px;
    box-shadow: 0 0 15px rgb(0 0 0 / 10%);
}

.our-missionCard h3 {
    font-size: calc(17px + (20 - 17) * ((100vw - 320px) / (1920 - 320)));
    margin-bottom: 15px;
    line-height: 28px;
    color: #2878c8;
    font-weight: 600;
}

.image-four-boxes-content h3 {
    font-size: calc(17px + (20 - 17) * ((100vw - 320px) / (1920 - 320)));
    margin-bottom: 15px;
    line-height: 28px;
    color: #2878c8;
    font-weight: 600;
}

.banner_btn {
    margin-top: 30px;
}

.btn-info {
    display: inline-block;
    min-width: 220px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    white-space: nowrap;
    transition: all .5s ease;
    padding: 0 20px;
    color: #2879c9;
    border: 2px solid #2879c9;
    line-height: 32px;
    background: 0 0;
    transition: all .3s ease-in-out 0s;
    margin-top: 20px;
}

.btn-info:hover {
    background-color: #2878c8;
}

.exceptionalCard {
    transition: all .3s ease-in-out;
    background: #fff;
    margin-bottom: 40px;
    height: 300px;
    position: relative;
    background-color: #fff;
    box-shadow: 0 1px 30px 1px rgb(42 22 156 / 10%);
    padding: 30px 25px 0;
    width: 100%;
    margin-bottom: 40px;
    box-sizing: border-box;
    h3 {
        font-size: calc(17px + (20 - 17) * ((100vw - 320px) / (1920 - 320)));
        margin-bottom: 15px;
        line-height: 28px;
        color: #2878c8;
        font-weight: 600;
    }
}

.contact-us_Section {
    padding: 60px 0;
    .form-wrap__flexbox {
        position: relative;
        // max-width: 500px;
        background-color: #fff;
        box-shadow: 0 0.2rem 1rem 0 rgb(0 0 0 / 11%);
        padding: 2rem 3rem;
        // margin: 0 auto;
        width: 100%;
    }
    .contact-num-info {
        padding: 1rem 1.5rem;
        color: #ffffff;
        background-color: #f5f5f5;
        background-repeat: no-repeat;
        box-sizing: border-box;
        background-size: cover;
        border-bottom: 1px solid #cecece;
    }
    .contact-form-right-sec #block-block-2 {
        background: #f5f5f5;
        padding: 1rem 1.3rem;
        box-sizing: border-box;
    }
    #contact-us {
        padding-top: 20px;
        padding-bottom: 60px;
    }
    .contact-num-info {
        padding: 1rem 1.5rem;
        color: #ffffff;
        background-color: #f5f5f5;
        background-repeat: no-repeat;
        box-sizing: border-box;
        background-size: cover;
        border-bottom: 1px solid #cecece;
    }
    .contact-num-info h3.inner-section-title {
        color: #2d2d2d;
        font-size: 24px;
        font-weight: 600;
        text-align: left;
    }
    .contact-num-info .contact-num li {
        padding: 12px;
        border-bottom: 1px solid #d6d6d6;
    }
    .contact-num-info .contact-num li i {
        display: inline-block;
        vertical-align: middle;
    }
    .contact-num-info h3.inner-section-title:after {
        width: 3rem;
        margin: 1rem 0;
    }
    .inner-section-title:after {
        height: 2px;
        width: 7rem;
        content: "";
        display: block;
        margin: 1rem auto;
        background: linear-gradient(to right, #50e590 50%, #0796c6 120%);
    }
    .contact-num-info .contact-num li .flag {
        display: inline-block;
        width: 31px;
        height: 21px;
        vertical-align: middle;
        margin-right: 10px;
    }
    .contact-form-right-sec #block-block-2 h3.dropdown-title:after {
        width: 3rem;
        margin: 1rem 0;
        height: 2px;
        content: "";
        display: block;
        /* width: 3rem; */
        /* margin: 1rem 0; */
        background: linear-gradient(to right, #50e590 50%, #0796c6 120%);
    }
    .contact-form-right-sec #block-block-2 p {
        line-height: 24px;
        font-size: 16px;
        letter-spacing: 0;
        color: #323f56;
    }
    .location-block {
        // background: url(../images/location-block-bg.png) #585755;
        background-position: right -34px bottom;
        background-repeat: no-repeat;
        background-size: 45%;
        padding: 1rem 1.3rem;
        box-sizing: border-box;
        color: #ffffff;
    }
    .contact-form-right-sec .location-block h3 a {
        color: #ffffff;
        font-family: Raleway, sans-serif;
        font-size: 1.5rem;
        font-weight: 600;
        text-transform: uppercase;
        line-height: 2rem;
    }
    .contact-form-right-sec .location-block p {
        font-size: 1.2rem;
        /* font-family: "OpenSans"; */
        line-height: 1.6rem;
        margin-bottom: 0;
    }
    .contact-form-right-sec #block-block-2 a.opening-btn {
        border: solid 2px #2878c8;
        border-radius: 50px;
        padding: 0.8rem 2em;
        margin: 0.5rem 0 0.2rem;
        display: inline-block;
        text-align: center;
        font-weight: 600;
        position: relative;
        -webkit-transition: 0.5s;
        transition: 0.5s;
        -webkit-appearance: none;
        /* text-transform: uppercase; */
        color: #2878c8;
        font-size: 0.9rem;
    }
    .contact-form-right-sec #block-block-2 a.opening-btn:hover {
        background: #2879c9;
        color: #fff;
        border: solid 2px #2878c9;
    }
    .location-block h3 {
        margin-bottom: 1rem;
    }
    .contact-form-right-sec #block-block-2 {
        background: #f5f5f5;
        padding: 1rem 1.3rem;
        box-sizing: border-box;
    }
    .contact-form-right-sec #block-block-2 h3.dropdown-title {
        color: #2d2d2d;
        font-size: 24px;
        font-weight: 600;
        text-align: left;
    }
    .contact-num-info .contact-num li:last-child {
        border-bottom: none;
        padding-bottom: 10px;
    }
    .contact-num-info .contact-num li a {
        color: #464646;
        /* font-family: "OpenSans"; */
        font-size: 18px;
        font-weight: 500;
        vertical-align: middle;
        margin-left: 1em;
    }
    .header-text-blue-2 {
        color: #707070 !important;
        font-size: 26px;
        text-align: left;
    }
    .fbox-icon i {
        font-size: 32px;
        color: #2879c9;
    }
}

.contactSection {
    padding: 60px 0;
    .tell-us-about {
        color: #333;
        /* text-transform: uppercase; */
        margin-bottom: 30px;
        font-size: 32px;
        font-weight: 700;
    }
    .contact-about-stats {
        -js-display: flex;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
        max-width: 570px;
        height: 150px;
        border: 1px solid #2879c9;
        margin-bottom: 60px;
        border-radius: 14px;
    }
    .contact-about-stats .stat-item {
        -ms-flex-preferred-size: 33.33333%;
        flex-basis: 33.33333%;
        -js-display: flex;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-flow: column nowrap;
        flex-flow: column nowrap;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        font-weight: 600;
        text-align: center;
        color: #2879c9;
    }
    .contact-about-stats .stat-num {
        display: block;
        margin: 0 auto;
        font-size: 40px;
    }
    .contact-about-stats .stat-item:not(:last-child) {
        border-right: 1px solid #2879c9;
    }
    .contact-about-stats .stat-desc {
        display: block;
        margin: 0 auto;
        width: 96px;
        font-size: 15px;
        line-height: 1.5;
        text-transform: uppercase;
    }
    // .fluid-width-video-wrapper {
    //     width: 100%;
    //     position: relative;
    //     padding: 0;
    // }
    @media only screen and (max-width: 991px) {
        .contact-about-stats {
            -js-display: flex;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -ms-flex-flow: row nowrap;
            flex-flow: row nowrap;
            max-width: 100%;
            height: 150px;
            border: 1px solid #2879c9;
            margin-bottom: 60px;
            border-radius: 14px;
        }
    }
    @media screen and (max-width:780px) {
        .contact-about-stats {
            height: 120px
        }
        .box-form-info-header {
            text-transform: uppercase;
            margin-bottom: 40px
        }
        .tell-us-about {
            margin-top: 30px !important
        }
        .contact-about-stats .stat-num {
            display: block;
            margin: 0 auto;
            font-size: 30px;
        }
    }
}

.consulting {
    .benifites-img {
        width: 65px;
        margin-bottom: 10px;
    }
    h3 {
        font-size: calc(17px + (20 - 17) * ((100vw - 320px) / (1920 - 320)));
        margin-bottom: 15px;
        line-height: 28px;
        color: #2878c8;
        font-weight: 600;
    }
}

@media (min-width: 992px) {
    .btn {
        padding: 10px 40px;
    }
    .d-lg-flex {
        display: flex;
    }
}

@media (max-width:767px) {
    .col-xs-6:nth-child(odd) {
        padding-right: 7.5px
    }
    .col-xs-6:nth-child(even) {
        padding-left: 7.5px
    }
    .button-box1 {
        margin-top: 20px;
        text-align: center
    }
    .port_img_box {
        margin-bottom: 15px;
    }
}

@media (max-width:575px) {
    .col-xs-4,
    .col-xs-6 {
        width: 50%;
    }
}